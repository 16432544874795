import { Injectable } from '@angular/core';
import { NavigationSkipped, Router, Scroll } from '@angular/router';
import { delay, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnchorOffsets, ScrollToAnchor } from '../../models/scroll.model';
import { MediaService } from '../../../../ui/src/lib/services/media-service/media.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ScrollToService {
    private staticScrollOffset = 100;
    private lastElementId: string;
    private anchorOffsets: ScrollToAnchor = {
        about: { default: 120, phone: 100, ['phone-xs']: 100 },
        services: { default: 70, tablet: 60, phone: 50, ['phone-xs']: 50 },
        partners: { default: 60, tablet: 60, phone: 50, ['phone-xs']: 50 },
        tax: { default: 136, tablet: 60, phone: 50, ['phone-xs']: 50 },
        legal: { default: 136, tablet: 60, phone: 50, ['phone-xs']: 50 },
        accounting: { default: 136, tablet: 60, phone: 50, ['phone-xs']: 50 },
        labor: { default: 136, tablet: 60, phone: 50, ['phone-xs']: 50 },
        certificate: { default: 136, tablet: 60, phone: 50, ['phone-xs']: 50 },
    };

    constructor(private router: Router, private mediaService: MediaService) {}

    public listenToGlobalNavEvents(): void {
        this.router.events
            .pipe(
                delay(50),
                filter((e): e is Scroll | NavigationSkipped => e instanceof Scroll || e instanceof NavigationSkipped),
                untilDestroyed(this)
            )
            .subscribe((e) => {
                const anchor = (e as Scroll)?.anchor || this.lastElementId;
                const anchorOffsets = this.anchorOffsets?.[anchor];
                const matchingBreakpoint = this.mediaService.getMatchingBreakpoint();
                const matchingAnchorOffset =
                    anchorOffsets?.[matchingBreakpoint as keyof AnchorOffsets] ?? anchorOffsets?.default;

                if (e instanceof Scroll) {
                    if (e?.anchor) {
                        this.lastElementId = e.anchor;

                        this.scrollToElementTop(e.anchor, matchingAnchorOffset);
                    } else {
                        this.scrollToTop();
                    }
                } else {
                    this.scrollToElementTop(this.lastElementId, matchingAnchorOffset);
                }
            });
    }

    public scrollToTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    public scrollToElementTop(id: string, offset?: number): void {
        const elementOffsetTop = document.getElementById(id)?.offsetTop || 0;
        const scrollOffset = offset ?? this.staticScrollOffset;

        window.scrollTo({
            top: elementOffsetTop - scrollOffset,
            behavior: 'smooth',
        });
    }
}
