<header [class.scrolled]="scrolled" [class.sticky]="sticky" class="header">
    <div class="app-container-full">
        <a class="logo" routerLink="/" aria-label="logo">
            <img ngSrc="/assets/icons/logo.svg" width="43" height="50" priority alt="Счетоводни услуги ИДИ">
        </a>

        <nav class="nav" [class.open]="menuOpen">
            <ul class="font-caption">
                <li>
                    <a routerLink="/" fragment="about" (click)="toggleMenu(false)">За нас</a>
                </li>

                <li>
                    <a routerLink="/" fragment="services" (click)="toggleMenu(false)">Услуги</a>

                    <ul class="show-tablet-below">
                        <li>
                            <a routerLink="/" fragment="tax" (click)="toggleMenu(false)">Данъчни услуги</a>
                        </li>

                        <li>
                            <a routerLink="/" fragment="legal" (click)="toggleMenu(false)">Юридически услуги</a>
                        </li>

                        <li>
                            <a routerLink="/" fragment="accounting" (click)="toggleMenu(false)">Счетоводни услуги</a>
                        </li>

                        <li>
                            <a routerLink="/" fragment="labor" (click)="toggleMenu(false)">Труд и работна заплата</a>
                        </li>

                        <li>
                            <a routerLink="/" fragment="certificate" (click)="toggleMenu(false)">Удостоверениe А1</a>
                        </li>
                    </ul>
                </li>

                <li>
                    <a routerLink="/" fragment="partners" (click)="toggleMenu(false)">Партньори</a>
                </li>
            </ul>

            <ngui-button
                label="СВЪРЖИ СЕ"
                elementType="link"
                href="/"
                fragment="contacts"
                class="show-tablet-below"
                (click)="toggleMenu(false)"
            />
        </nav>

        <ngui-button
            label="СВЪРЖИ СЕ"
            elementType="link"
            buttonStyle="outline"
            href="/"
            fragment="contacts"
            class="show-tablet-above"
        />

        <button
            class="menu-btn show-tablet-below"
            [class.open]="menuOpen"
            (click)="toggleMenu(!menuOpen)"
            aria-label="menu-btn"
        >
            <span></span>
        </button>
    </div>
</header>