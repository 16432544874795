<footer>
    <div class="app-container">
        <div class="col col-left">
            <div class="nav font-micro">
                <a routerLink="/terms">Общи условия</a>

                <a routerLink="/privacy-policy">Политика за поверителност</a>
            </div>
        </div>

        <div class="col col-right">
            <p class="font-micro">© {{year}} Счетоводни услуги ИДИ ЕООД</p>
        </div>
    </div>
</footer>