import { RoutesWithData } from './models/route-data.model';
import { GenericRouteGuard } from './guards/generic-route.guard';

export const appRoutes: RoutesWithData = [
    {
        path: '',
        loadComponent: () => import('./pages/home/home-page.component').then((mod) => mod.HomePageComponent),
        data: {
            routeTitle: 'Счетоводни услуги',
            routeDescription:
                'Опростете вашите финанси с нашите експертни счетоводни решения. Ние предлагаме счетоводство, подготовка на данъци и други, за да ви помогнем да успеете.',
        },
        canActivate: [GenericRouteGuard],
    },
    {
        path: 'terms',
        loadComponent: () => import('./pages/legal/terms/terms.component').then((mod) => mod.TermsComponent),
        data: {
            useShell: false,
            routeTitle: 'Общи условия',
            routeDescription: 'Общи условия на Счетоводни услуги ИДИ',
        },
        canActivate: [GenericRouteGuard],
    },
    {
        path: 'privacy-policy',
        loadComponent: () =>
            import('./pages/legal/privacy-policy/privacy-policy.component').then((mod) => mod.PrivacyPolicyComponent),
        data: {
            useShell: false,
            routeTitle: 'Политика за поверителност',
            routeDescription: 'Политика за поверителност на Счетоводни услуги ИДИ',
        },
        canActivate: [GenericRouteGuard],
    },
    {
        path: '404',
        loadComponent: () =>
            import('./pages/not-found/not-found-page.component').then((mod) => mod.NotFoundPageComponent),
        data: {
            useShell: false,
            routeTitle: '404',
            routeDescription:
                'Опростете вашите финанси с нашите експертни счетоводни решения. Ние предлагаме счетоводство, подготовка на данъци и други, за да ви помогнем да успеете.',
        },
        canActivate: [GenericRouteGuard],
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
