import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RouteData } from '../models/route-data.model';
import { MetaHandlerService } from '../services/meta-handler/meta-handler.service';

@Injectable({
    providedIn: 'root',
})
export class GenericRouteGuard implements CanActivate {
    constructor(private metaHandlerService: MetaHandlerService) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return of(true).pipe(
            switchMap(() => {
                const data = next.data as RouteData;

                if (data?.routeTitle || data?.routeDescription) {
                    this.metaHandlerService.setPageMeta(data);
                }

                return of(true);
            })
        );
    }
}
