import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { distinctUntilChanged, fromEvent, map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@ngui-nx/ui';
import { NgOptimizedImage } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'ngui-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterModule, ButtonComponent, NgOptimizedImage],
})
export class HeaderComponent implements OnInit {
    @Input() public sticky = false;

    public scrolled = false;
    public menuOpen = false;

    constructor(private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.handleScroll();
    }

    private handleScroll(): void {
        if (!this.sticky) {
            setTimeout(() => {
                this.scrolled = window.scrollY > 0;
                this.cdr.detectChanges();

                fromEvent(window, 'scroll')
                    .pipe(
                        map(() => window.scrollY > 0),
                        distinctUntilChanged(() => this.scrolled),
                        untilDestroyed(this)
                    )
                    .subscribe((scrolled) => {
                        this.scrolled = scrolled;
                        this.cdr.detectChanges();
                    });
            }, 0);
        }
    }

    public toggleMenu(toggle: boolean): void {
        this.menuOpen = toggle;
    }
}
